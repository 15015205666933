import './App.css';
/**
 * 
 * test
 */
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="techno-craft.png" className="App-logo" alt="logo" />
        <p>
          En construction !  le site arrive bientôt
          <br/>
          In construction ! the web site is coming soon
        </p>
        <a
          className="App-link"
          href="https://www.amazon.fr/TECHNO-CRAFT-Raspberry-Alimentation-dissipateurs/dp/B08PDNTJYW/ref=sr_1_11?crid=11WCS6ZASXEF5&dchild=1&keywords=kit+raspberry+pi+4+8go&qid=1623598152&sprefix=kit+raspberry+pi+%2Caps%2C205&sr=8-11"
          target="_blank"
          rel="noopener noreferrer"
        >
          En attendant veuillez visiter notre boutique Amazon !
        </a>
        ou nous contacter sur: contact@techno-craft.fr
      </header>
    </div>
  );
}

export default App;
